body {
  background-color:white;
 

  }
h2{
  margin-top: 50px;
}

.appForm {
  width:100%;
  margin: 55px 0;
  box-sizing: border-box;
}
.date{
  text-align: left;
  padding-left: 35%;
}
.Text{
  width: 30%;
}

h1{
  text-align: center;
  font-style: italic;
}
h2{
  text-align: center;
  
}
section{
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  padding: 5px;
  margin-top: 0px;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 12px;
  box-sizing: border-box;
  background-color: white;
  resize: none;
  margin-bottom: 0px;
}
.convictNotice{
  vertical-align: top;
}
input[type=radio]{
  margin-left: .3cm;
  margin-right: .3cm;
}
.drop{
  width: 25%;
  margin-bottom: 20px;
  
}
label{
  margin-top: 20px;
  margin-bottom: 20px;
}
button {
  width: 20%;
  height: 20%;

}
.pageHead {
  font-size: 60px;
  text-align: left;
  margin-bottom: 25px;
}
p {
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 5%;
  background-color: white;
  box-shadow: -10px 0 5px -5px #333, 10px 0 5px -5px #333;
}
.pageBody {
  padding-left: 2%;
}
table#homeInfo {
  width: 100%;
  table-layout: fixed;
  padding-bottom: 50%;
}
.homeContent {
  padding-left: 180px;
  padding-right: 180px;
  position: relative;
  background-color: gray;
}
.employeeTable {
  width:90%;
  table-layout:fixed;
  margin-left: auto;
  margin-right: auto;
}
.table_cell{
  display:table-cell;
  justify-content: center;
  padding-left: 10%;
}
.table_cell2{
  display:table-cell;
  width: 50%;
  padding-left:11%;
  padding-top: 10%;

}
.paragraph{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-left:15%;
  padding-right:15%;
  padding-bottom: 6%;
}
.heading{
  font-size: 75px;
  text-align: center;
  font-weight: 500;
}
.tableHead{
  font-size: 20px;
  font-weight: bold;
}
.tableContent{
  padding: 0;
  margin: 0;
}
.employeeImg{
  border: 2px solid black;
  object-fit: cover;
}
.homeLinks{
  display: table;
  table-layout: fixed;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
}
.linkCat{
  display: table-cell;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
.linkTiles:hover{
  background-color: #4176de;
}
.linkTiles{
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 10px 8px #888888;
  border-radius: 10px;
  background-color: #073C70;
  color: white;
}
.linkRef{
  text-decoration: none;
  color: white;
}
.iconNav{
  color: white;
}
table.certTable{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  
}
td.certCell{
  padding-bottom: 2%;
  padding-left: 2%;
  padding-top: 1%;
  padding-right: 1%;
  width: 60%;
}
td.certCell2{
  text-align: left;
  padding-left: 10%;
  justify-content: center;
  width: 100%;
}
.orgContent{
  margin: 2%;
  font-size: 14px;
}
.contentLinks{
  font-weight: 500;
  font-size: 16px;
}
hr.bodyDiv{
  border: 1px solid #4176de;
}
.paragraph2{
  text-align: left;
}
.images{
  border: 3px solid #073C70;
}
.findusTable{
  width:100%;
  table-layout:fixed;
}
.findusCell2{
  text-align: right;
  
}
td.homeImg{
  text-align: center;
  width: 40%;
  height: 100%;
}
td.homeCell{
  vertical-align: top;
  padding-right: 3%;
}
.orgCell{
  text-align: center;
  width: 1%;
}
.orgCell2{
  width: 100%;
}
.homeInfo{
  font-size: 21px;
  font-weight: 450;
}
a.bottomText{
  text-decoration: none;
}
.findusCell{
  padding: 2%;
  font-size: 35px;

}
.paragraph3{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-left:10%;
  padding-right:10%;
  padding-bottom: 5%;
}
.heading2{
  font-size: 45px;
  text-align: center;
  font-weight: 500;
  color: #073C70;
}
.appCell{
  border: 3px solid #073C70;
  padding: 2%;
}
.productHead{
  font-size: 40px;
  font-weight: 500;
  color: #073C70;
  padding-top: 5%;
}
.product{
  font-weight: bold;
  font-size: 26px;
  text-decoration: underline;
}
td.productCell{
  padding-bottom: 5%;
  vertical-align: top;
  padding-left: 5%;
  padding-right: 5%;
  width: 60%;
}
.productInfo{
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 2%;
  color: #073C70;
}
hr.headerLine{
  width: 80%;
  background-color: #4176de;
  height: 2px;
}
.images2{
  padding-top: 8%;
  padding-right: 8%;
  text-align: center;
  vertical-align: top;
}
td.productCell2{
  padding-bottom: 5%;
  vertical-align: top;
  padding-left: 5%;
  padding-right: 5%;
  width: 58%;
}
h5.productList{
  padding-top: 8%;
  text-decoration: underline;
}
h2.productList{
  padding-top: 8%;
  text-decoration: underline;
}
.productCell3{
  padding-bottom: 5%;
  vertical-align: top;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}
.bottomImg{
  text-align: center;
}
td.productImg{
  text-align: center;
  padding-bottom: 5%;
}
.productImg2{
  padding-top: 5%;
  justify-content: left;
}
td.productCell4{
  vertical-align: top;
  width: 50%;
}
.bottomImg2{
  text-align: center;
}
.images3{
  padding: 2%;
  border: 3px solid #073C70;
}
.productInline{
text-align: center;
width: 100%;
}
table.infoTable2{
  padding: 0%;
  justify-content: center;
}
ul.AVList{
  display: inline-block;
  text-align: left;
  padding: 1%;
  margin-left: 37%;
  width: 100%;
  padding-bottom: 5%;
}
li{
  padding: 1%;
}
.bottomImg3{
  text-align: center;
  padding-top: 5%;
}
ul.marketList{
  font-size: 26px;
  font-weight: 600;
}
.paragraph4{
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding-left:15%;
  padding-right:15%;
  padding-bottom: 4%;
}
.productCell4{
  padding-bottom: 5%;
  vertical-align: top;
  padding-left: 5%;
  padding-right: 2%;
  width: 100%;
}
.appField{
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
}
.authorization{
  width: 100%;
  font-size: 12px;
  justify-content: center;
  padding: 2%;
  padding-top: 0%!important;
}