.Container{ 
    size: 100%;
    overflow: hidden; 
    color: rgb(255, 255, 255);
    
}
.div {
    display: block;
}


.subBody {
    background-color: gray;
    padding-left: 180px;
    padding-right: 180px;
    overflow: hidden;
    min-height: 100%;
}
.navbar {
    border-radius: 0;
        background-color: #4176de;
        width: 100%;
        
}
.mv-auto{
    width: 100%;
    padding-left: 50%;
    margin-left: -325px;
}
@media (max-width: 992px) {
    body.subBody {
      padding: 0;
      margin: 0 0 420px;
    }
    div.homeContent {
        padding: 0;
    }
    .footer {
        position: fixed;
    }

  }